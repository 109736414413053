<template>
  <p 
    class="activeString" 
    :ref="refName"
    @mouseenter="emitActivateActiveStringOverride()"
    @mouseleave="emitDeActivateActiveStringOverride()"
    @touchstart="emitActivateActiveStringOverride()"
    @touchend="emitDeActivateActiveStringOverride()"
  >
    {{ text }}
  </p>
</template>

<script>

import { eventBus } from '@/event-bus.js';

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    domainKey: {
      type: String,
      required: true,
    },
    refName: {
        type: String,
        required: true,
    },
    isActive: {
        type: Boolean,
        default: false,
    }
  },
  mounted() {
    this.activeSwitch(this.isActive);
  },
  watch: {
    isActive(newValue) {
        this.activeSwitch(newValue);
    }
    },
  methods: {
    activeSwitch(newValue = this.isActive) {
      if (newValue === true) {
          this.$refs[this.refName].classList.add('active');
      } else {
          this.$refs[this.refName].classList.remove('active');
      } 
    },
    emitActivateActiveStringOverride() {
      eventBus.emit('activateActiveStringOverride', {domainKey: this.domainKey, refName: this.refName});
    },
    emitDeActivateActiveStringOverride() {
      eventBus.emit('deActivateActiveStringOverride', {domainKey: this.domainKey, refName: this.refName});
    },
  },
}
</script>


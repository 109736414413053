<template>
  <div class="domain-cards-container">
    <div v-for="(domain, domainKey) in domains" :key="domainKey" class="domain-card" :title="domain.title" :id="domainKey" @touchmove="handleTouchMove($event, domainKey)">
      <!--@mouseenter="activateDomainOverride(domainKey)" @mouseleave="deActivateDomainOverride(domainKey)"-->
      <h2 class="domain-card-title">{{ domain.title }}</h2>
      <img class="domain-card-image" :src="domain.image.src" :alt="domain.image.alt" />
      <div class="active-paragraph-container">
        <ActiveParagraph
          :domainKey="domainKey"
          :paragraph="paragraphs[domainKey].paragraph"
          :activeStrings="paragraphs[domainKey].active_strings"
        />
      </div>
      <div class="tool-icons-container">
        <TrickySVG
          v-for="(icon, iconKey) in domain.icons"
          :key="iconKey"
          :className="icon.className"
          :relSrc="icon.relSrc"
          :isActive="icon.isActive"
          :title="iconKey"
          :href="icon.href"
          auxiliaryClassName="tool-icon"
          @mouseenter="activateIconOverride(domainKey, iconKey)"
          @mouseleave="deactivateOverride(domainKey, iconKey)"
          @touchstart="activateIconOverride(domainKey, iconKey)"
          @touchend="deactivateOverride(domainKey, iconKey)"
          @touchcancel="deactivateOverride(domainKey, iconKey)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TrickySVG from '@/components/TrickySVG.vue'
import ActiveParagraph from './ActiveParagraph.vue';
import { onMounted } from 'vue';
import DomainCardData from '@/misc/DomainCardData.js'

import { eventBus } from '@/event-bus.js';

export default {
  name: 'DomainCard',
  components: {
    TrickySVG,
    ActiveParagraph,
  },
  mounted() {
    this.rotateActivity();
    this.observeDomainCards()
    eventBus.on('activateActiveStringOverride', this.activateActiveStringOverride);
    eventBus.on('deActivateActiveStringOverride', this.deActivateActiveStringOverride);
  },
  methods: {
    rotateActivity() {
      this.domainIdx = 0;
      this.activeElementIdx = 0;
      this.rotate();
    },
    rotate() {
        const domainKey = Object.keys(this.domains)[this.domainIdx];
        const activeStrings = this.paragraphs[domainKey].active_strings;
        
        // if the current target domain is visible on the screen, otherwise skip to the next one that is
        if (this.domainCardsOnScreen[domainKey] != false && (this.domainOverride == false || this.domainOverride == domainKey) ) {
          const elementKey = Object.keys(activeStrings)[this.activeElementIdx];
          const element = activeStrings[elementKey];
          const activeKey = Object.keys(this.paragraphs[domainKey].active_strings)[this.activeElementIdx];

          // switch the active string on
          this.activeStringSwitch(domainKey, activeKey)

          // switch the icons on
          element.icons.forEach(iconKey => {
            this.iconSwitch(domainKey, iconKey)
          });
          
          this.rotationTimeout = setTimeout(() => {
            // switch the active icons off
            element.icons.forEach(iconKey => {
              this.iconSwitch(domainKey, iconKey)
            });
            // switch the active string off
            this.activeStringSwitch(domainKey, activeKey)

            this.activeElementIdx = (this.activeElementIdx + 1) % Object.keys(activeStrings).length;
            if (this.activeElementIdx === 0) {
              this.domainIdx = (this.domainIdx + 1) % Object.keys(this.domains).length;
            }

            this.rotate();
          }, 5000);

        } else {
          this.rotationTimeout = setTimeout(() => {
            //console.log("not visible!")
            this.domainIdx = (this.domainIdx + 1) % Object.keys(this.domains).length;
            this.activeElementIdx = 0
            clearTimeout(this.rotationTimeout);
            this.rotate();
          }, 200);
        }
      },
      
    iconSwitch: function(domainKey, iconKey) {
      const currentState = this.domains[domainKey].icons[iconKey].isActive
      this.domains[domainKey].icons[iconKey].isActive = !currentState
    },
    activeStringSwitch: function(domainKey, activeStringKey) {
      const currentState = this.paragraphs[domainKey].active_strings[activeStringKey].isActive;
      this.paragraphs[domainKey].active_strings[activeStringKey].isActive = !currentState; 
    },
    clearActiveIcons: function() {
      Object.keys(this.domains).forEach(domainKey => {
        Object.keys(this.domains[domainKey].icons).forEach(iconKey => {
          this.domains[domainKey].icons[iconKey].isActive = false;
        });
      });
    },
    clearActiveElements: function() {
      Object.keys(this.paragraphs).forEach(domainKey => {
        Object.keys(this.paragraphs[domainKey].active_strings).forEach(activeKey => {
          this.paragraphs[domainKey].active_strings[activeKey].isActive = false;
        });
      });
    },
    activateIconOverride: function(domainKey, iconKey) {
      clearTimeout(this.rotationTimeout);
      if (this.deactivationTimeout) {
        clearTimeout(this.deactivationTimeout);
      }

      this.clearActiveIcons()
      this.clearActiveElements()

      // Activate the specific icon
      this.iconSwitch(domainKey, iconKey)

      // Activate buddy element if exists
      const pairActiveStringIdx = this.domains[domainKey].icons[iconKey].pairActiveStringIdx;
      this.paragraphs[domainKey]["active_strings"][pairActiveStringIdx].isActive = true;
      
    },
    activateDomainOverride: function(domainKey) {
      this.domainOverride = domainKey;
    },
    deActivateDomainOverride: function() {
      this.domainOverride = false;
    },
    activateActiveStringOverride: function(args) {
      const domainKey = args.domainKey;
      const refName = args.refName;

      clearTimeout(this.rotationTimeout);
      if (this.deactivationTimeout) {
        clearTimeout(this.deactivationTimeout);
      }

      this.clearActiveIcons()
      this.clearActiveElements()

      // Activate the specific icon
      this.activeStringSwitch(domainKey, refName)

      // Activate buddy element if exists
      const pairIcons = this.paragraphs[domainKey].active_strings[refName].icons;
      pairIcons.forEach(iconKey => {
        this.iconSwitch(domainKey, iconKey)
      });
      
    },
    deactivateOverride: function(domainKey) {
      this.deactivationTimeout = setTimeout(() => {
        clearTimeout(this.rotationTimeout);
        this.clearActiveIcons()
        this.clearActiveElements()
        //if (domainKey) {this.activateDomainOverride(domainKey);}
        this.rotate();
      }, 3000)
    },
    deActivateActiveStringOverride: function(args) {
      const domainKey = args.domainKey;

      this.clearActiveIcons()
      this.clearActiveElements()

      this.activateDomainOverride(domainKey)

      clearTimeout(this.rotationTimeout);
      this.rotate()
    },
    deactivateOverride: function() {
      this.deactivationTimeout = setTimeout(() => {
        clearTimeout(this.rotationTimeout);
        this.clearActiveIcons()
        this.clearActiveElements()
        this.domainOverride = false;
        this.rotate();
      }, 3000)
    },
    handleTouchMove: function(event) {
      const touch = event.touches[0];
      const element = document.elementFromPoint(touch.clientX, touch.clientY);

      if (element) {
        const domain = element.closest('.domain-card')
        const icon = element.closest('.tool-icon')

        if (domain && icon) {
          const iconKey = icon.getAttribute('title');
          const domainKey = domain.getAttribute('id')
          //console.log("handleTouchMove", domainKey, iconKey)
          //console.log(element.closest('.tool-icon'))

          if (domainKey != this.prevDomainKey || iconKey != this.prevIconKey) {
            
            if (this.prevDomainKey != null & this.prevDomainKey != null) {
              this.iconSwitch(this.prevDomainKey, this.prevIconKey)
              this.activateIconOverride(domainKey, iconKey);
            }
            this.prevDomainKey = domainKey;
            this.prevIconKey = iconKey;
        }
      }
      }
    },
    observeDomainCards() {
      const domainCards = document.querySelectorAll('.domain-card');

      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          this.updateDomainVisibilityStatus(entry.target.id, entry.isIntersecting)
        });
      }, { threshold: 0.5 });

      domainCards.forEach(card => observer.observe(card));
    },
    updateDomainVisibilityStatus(domainKey, new_visibility) {
      this.domainCardsOnScreen[domainKey] = new_visibility
    }
  },
  watch: {
    'domainCardsOnScreen': {
      handler(newValue) {
        //console.log("terminating timeout, card no longer visible")
        const domainKey = Object.keys(this.domains)[this.domainIdx];
        if (!newValue[domainKey]) {
          clearTimeout(this.rotationTimeout);
          clearTimeout(this.deactivationTimeout);
          this.clearActiveIcons()
          this.clearActiveElements()
          this.domainOverride = false;
          this.rotate();
        }
      },
      deep: true
    },
    'domainOverride': {
      handler(newValue) {
        clearTimeout(this.rotationTimeout);
        this.clearActiveIcons()
        this.clearActiveElements()
        this.rotate();
      }
    }
  },
  data() {
    return {
      domainIdx: 0,
      activeElementIdx: 0,
      domains: DomainCardData["domains"],
      paragraphs: DomainCardData["paragraphs"],
      rotationTimeout: null,
      deactivationTimeout: null,
      prevIconKey: null,
      prevDomainKey: null,
      domainCardsOnScreen: {},
      domainOverride: false
    }
  }
}
</script> text
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'jquery';


// Import CSS files
import './css/global.css';
import './css/header.css';
import './css/TypewriterTitle.css';
import './css/frame1.css';
import './css/ContactForm.css';
import './css/ContactInfo.css';
import './css/ProjectsCarousel.css';
import './css/ProjectDetails.css';
import 'vue3-carousel/dist/carousel.css';
import './css/DomainCard.css';
import './css/DomainIcons.css';
import './css/ActiveParagraph.css';

const app = createApp(App);

import VueSimpleSVG from 'vue3-simple-svg'

app.use(router).use(VueSimpleSVG).mount('#app');

<template>
  <p class="active-paragraph">
    <template v-for="(part, index) in formattedParagraph" :key="index">
      <component :is="part.component" v-if="part.component" v-bind="part.props"></component>
      <p class="paragraphText" v-else v-html="part.text"></p>
    </template>
  </p>
</template>

<script>
import ActiveString from './ActiveString.vue';

export default {
  components: {
    ActiveString,
  },

  props: {
    domainKey: {
      type: String,
      required: true,
    },
    paragraph: {
      type: String,
      required: true,
    },
    activeStrings: {
      type: Object,
      required: true,
    }
  },

  computed: {
    activeElements() {
      return Object.entries(this.activeStrings).map(([key, value]) => ({
        key,
        text: value.text,
        icons: value.icons,
        isActive: value.isActive,
      }));
    },

    formattedParagraph() {
      const parts = [];
      let lastIndex = 0;

      this.activeElements.forEach((element) => {
        const startIndex = this.paragraph.indexOf(`**${element.key}**`);
        if (startIndex !== -1) {
          // Add the text before the active string
          if (startIndex > lastIndex) {
            parts.push({ text: this.paragraph.slice(lastIndex, startIndex) });
          }

          // Add the active string component
          parts.push({
            component: 'ActiveString',
            props: {
              domainKey: this.domainKey,
              refName: element.key,
              text: element.text,
              isActive: element.isActive,
            },
          });

          lastIndex = startIndex + element.key.length + 4; // 4 is the length of "**" and "**"
        }
      });

      // Add the remaining text after the last active string
      if (lastIndex < this.paragraph.length) {
        parts.push({ text: this.paragraph.slice(lastIndex) });
      }

      return parts;
    },
  },
};
</script>

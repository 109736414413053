<template>
    <div class="project-details-container">
      <Carousel class="project-details" ref="detailsCarousel" :items-to-show=1.0001 :transition="animationSpeed" :wrap-around="true" @slide-start="slideStart" :touchDrag="drag" :mouseDrag="drag">
        
        <Slide v-for="(project_slide, index) in activeSlides" :key="index">
          <div class="carousel__item slide">
            <div class="slide-content">
                <div class="title-container">
                  <h3 class="slide-title">{{ project_slide.title }}</h3>
                </div>
                <div class="description-container">
                  <p class="slide-desc">{{ project_slide.description }}</p>
                </div>
                <div class="image-container">
                  <expandable-image class="slide-image" :thumb="project_slide.thumb" :image="project_slide.img"/>
                </div>
            </div>
          </div>
        </Slide>

      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>
    </div>
  </template>

  <script>
  import { defineComponent, watch } from 'vue'
  import { Carousel, Navigation, Slide, Pagination } from 'vue3-carousel'
  import ExpandableImage from './ExpandableImage.vue'
  import 'vue3-carousel/dist/carousel.css'

  import { eventBus } from '@/event-bus.js';
  
  export default defineComponent({
    props: {
      swipeDirection: {
        required: true
      },
      activeProject: {
        required: true
      },
      activeSlides: {
        required: true
      },
      animationSpeed: {
        required: true
      }
    },
    data() {
      return {
        prevSlideIdx: 0,
        nextProjectLock: false,
        drag: true,
      }
    },
    name: 'WrapAround',
    components: {
      Carousel,
      Slide,
      Pagination,
      Navigation,
      ExpandableImage
    },
    watch: {
      activeProject: 'focusFirstSlide',
    },
    mounted() {
      eventBus.on('toggleDrag', this.toggleDrag);
    },
    methods: {
      focusFirstSlide() {
        ///this.$refs.details-carousel.slideTo(0)
        
          //console.log(this.$refs.details-carousel)
          var maxSlide = Object.keys(this.$props.activeSlides).length

          //console.log(this.$props.activeProject, maxSlide)

          this.nextProjectLock = true; // prevent runaway project scrolling
          if (this.$props.swipeDirection == "left") {
            maxSlide = -maxSlide
          } 
          //console.log("scrolling to", maxSlide)
          this.$refs.detailsCarousel.slideTo(maxSlide);
          this.nextProjectLock = false;
      },
      slideStart(slide) {
        let newIdx = slide.slidingToIndex;

        const nSlides = Object.keys(this.$props.activeSlides).length;
        if (newIdx == nSlides) {
          newIdx = 0;
        } else if (newIdx == -1) {
          newIdx = nSlides - 1;
        }

        const maxIdx = nSlides - 1;
        // manage the wrap around edge cases 
        if (!this.nextProjectLock) {
          if (((newIdx * this.prevSlideIdx) == 0) && (newIdx + this.prevSlideIdx) == maxIdx)  {
            if (newIdx < this.prevSlideIdx) {
              //console.log("nextPtoject!");
              eventBus.emit('nextProject');
            } else {
              //console.log("prevProject!");
              eventBus.emit('prevProject');
            }
          }
        }
        this.prevSlideIdx = newIdx;
      },
    toggleDrag() {
        this.drag = !this.drag;
      },
    }
  })
  </script>
  

<template>
    <div class="contact-info-container">
        <div class="contact-info" @click="openWhatsApp">
            <TrickySVG
                className="icon-whatsapp"
                relSrc="assets/icons/whatsapp"
                title="icon-whatsapp"
                :isActive="false"
            />
            <div class="contact-text"><p>+43 677 643 79087</p></div>
        </div>
        <div class="contact-info" @click="openEmail">
            <TrickySVG
                className="icon-email"
                relSrc="assets/icons/email"
                title="icon-email"
                :isActive="false"
            />
            <div class="contact-text"><p>office@natealexeev.dev</p></div>
        </div>
    </div>
</template>

<script>
import TrickySVG from '@/components/TrickySVG.vue'

export default {
    components: {
        TrickySVG
    },
    methods: {
        openWhatsApp() {
            window.open('https://wa.me/4367764379087', '_blank');
        },
        openEmail() {
            window.location.href = 'mailto:office@natealexeev.dev';
        }
    }
}
</script>
<template>
    <a :href="href" :class="[className, auxiliaryClassName]" :ref="className" :title="title" :alt="title" v-html="svgContent" target="_blank"></a>
</template>

<script>
import { htmlPrefilter } from 'jquery';

export default {
    props: {
        className: { type: String, required: true },
        relSrc: { type: String, required: true }, // relSrc assumes @ at its beginning and the .svg extension at the end   //
        isActive: {type: Boolean, required: true},
        auxiliaryClassName: { type: String, required: false },
        title: { type: String, required: false},
        href: { type: String, required: false}
    },
    computed: {
        svgContent() {
            const content = require(`!!svg-inline-loader!../${this.$props.relSrc}.svg`)
            const svg = content.default || content
            return svg;
        }
    },
    mounted() {
        this.updateSVGStyle();
    },
    watch: {
        isActive(newValue) {
            this.updateSVGStyle(newValue);
        }
    },
    methods: {
        updateSVGStyle(newValue = this.isActive) {
            if (newValue === true) {
                this.$refs[this.className].classList.add('active');
            } else {
                this.$refs[this.className].classList.remove('active');
            }
        }
    },
}
</script>
const domains = {
  'data-analytics': {
      title: 'Data Analytics',
      image: {
          src: require('@/assets/images/domains/production/data-analytics.webp'),
          alt: 'data-analytics'
      },
      description: 'Explore and analyze data using a variety of tools and languages.',
      icons: {
          python: { className: 'icon-python', relSrc: 'assets/icons/tools/python', pairActiveStringIdx: "idx1", isActive: false, href: 'https://www.python.org/' },
          selenium: { className: 'icon-selenium', relSrc: 'assets/icons/tools/selenium', pairActiveStringIdx: "idx1", isActive: false, href: 'https://www.selenium.dev/' },
          powerBI: { className: 'icon-powerBI', relSrc: 'assets/icons/tools/powerBI', pairActiveStringIdx: "idx1", isActive: false, href: 'https://www.microsoft.com/en-us/power-platform/products/power-bi' },
          R: { className: 'icon-R', relSrc: 'assets/icons/tools/R', pairActiveStringIdx: "idx2", isActive: false, href: 'https://www.r-project.org/' },
          shiny: { className: 'icon-shiny', relSrc: 'assets/icons/tools/shiny', pairActiveStringIdx: "idx3", isActive: false, href: 'https://shiny.posit.co/' },
          flask: { className: 'icon-flask', relSrc: 'assets/icons/tools/flask', pairActiveStringIdx: "idx3", isActive: false, href: 'https://flask.palletsprojects.com/en/3.0.x/' },
          tableau: { className: 'icon-tableau', relSrc: 'assets/icons/tools/tableau', pairActiveStringIdx: "idx2", isActive: false, href: 'https://www.tableau.com/' },
          virtualBox: { className: 'icon-virtualbox', relSrc: 'assets/icons/tools/virtualbox', pairActiveStringIdx: "idx1", isActive: false, href: 'https://www.virtualbox.org/' },
      }
  },
  'ai-engineering': {
      title: 'AI Engineering',
      image: {
          src: require('@/assets/images/domains/production/ai.webp'),
          alt: 'ai-engineering'
      },
      description: 'Build and deploy AI models using popular frameworks and languages.',
      icons: {
          pytorch: { className: 'icon-pytorch', relSrc: 'assets/icons/tools/pytorch', pairActiveStringIdx: "idx1", isActive: false, href: 'https://pytorch.org/' },
          keras: { className: 'icon-keras', relSrc: 'assets/icons/tools/keras', pairActiveStringIdx: "idx1", isActive: false, href: 'https://keras.io/' },
          tensorflow: { className: 'icon-tensorflow', relSrc: 'assets/icons/tools/tensorflow', pairActiveStringIdx: "idx1", isActive: false, href: 'https://www.tensorflow.org/' },
          python: { className: 'icon-python', relSrc: 'assets/icons/tools/python', pairActiveStringIdx: "idx1", isActive: false, href: 'https://www.python.org/' },
          scikitLearn: { className: 'icon-sklearn', relSrc: 'assets/icons/tools/sklearn', pairActiveStringIdx: "idx1", isActive: false, href: 'https://scikit-learn.org/stable/' },
          autoML: { className: 'icon-automl', relSrc: 'assets/icons/tools/automl', pairActiveStringIdx: "idx1", isActive: false, href: 'https://www.automl.org/automl/' }
      }
  },
  'web-development': {
      title: 'Web Development',
      image: {
          src: require('@/assets/images/domains/production/fullstack.webp'),
          alt: 'web-development'
      },
      description: 'Create web applications using modern frameworks and tools.',
      icons: {
          figma: { className: 'icon-figma', relSrc: 'assets/icons/tools/figma', pairActiveStringIdx: "idx1", isActive: false, href: 'https://www.figma.com/' },
          nuxt: { className: 'icon-nuxt', relSrc: 'assets/icons/tools/nuxt', pairActiveStringIdx: "idx2", isActive: false, href: 'https://nuxt.com' },
          tailwind: { className: 'icon-tailwind', relSrc: 'assets/icons/tools/tailwind', pairActiveStringIdx: "idx2", isActive: false, href: 'https://tailwindcss.com/' },
          django: { className: 'icon-django', relSrc: 'assets/icons/tools/django', pairActiveStringIdx: "idx2", isActive: false, href: 'https://www.djangoproject.com/' },
          vuejs: { className: 'icon-vue', relSrc: 'assets/icons/tools/vue', pairActiveStringIdx: "idx2", isActive: false, href: 'https://vuejs.org/' },
          docker: { className: 'icon-docker', relSrc: 'assets/icons/tools/docker', pairActiveStringIdx: "idx3", isActive: false, href: 'https://www.docker.com/' },
          ubuntu: { className: 'icon-ubuntu', relSrc: 'assets/icons/tools/ubuntu', pairActiveStringIdx: "idx3", isActive: false, href: 'https://ubuntu.com/server' },
          gitlab: { className: 'icon-gitlab', relSrc: 'assets/icons/tools/gitlab', pairActiveStringIdx: "idx2", isActive: false, href: 'https://about.gitlab.com/' },
      } 
  }
}

const paragraphs = {
  'data-analytics': {
    paragraph: 'Our data analytics services deliver **idx1**, **idx2** as well as **idx3** to fuel your business growth.',
    active_strings: {
      idx1: {
        text: "data acquisition & processing", 
        icons: ["python", "selenium", "virtualBox"],
        isActive: false,
      },
      idx2: {
        text: "data exploration & visualization", 
        icons: ["R", "tableau", "powerBI"],
        isActive: false,
      },
      idx3: {
        text: "dashboard development", 
        icons: ["shiny", "flask"],
        isActive: false,
      } 
    },  
  },
  'ai-engineering': {
    paragraph: 'Unlock the potential of your data with our **idx1**, delivering bespoke models that drive creativity and efficiency.', 
    active_strings: {
      idx1: {
        text: "AI Engineering Services", 
        icons: ["pytorch", "keras", "tensorflow", "python", "scikitLearn", "autoML"],
        isActive: false,
      } 
    },  
  },
  'web-development': {
    paragraph: "Level up your game with impactful custom websites and web apps! We handle everything from **idx1** to **idx2** to **idx3**.", 
    active_strings: {
      idx1: {
        text: "UI/UX Design", 
        icons: ["figma"],
        isActive: false,
      },
      idx2: {
        text: "FullStack Engineering", 
        icons: ["nuxt", "tailwind", "django", "vuejs", "gitlab"],
        isActive: false,
      },
      idx3: {
        text: "Deployment & Maintenance", 
        icons: ["docker", "ubuntu"],
        isActive: false,
      }
    },  
  }
}

export default {
  domains: domains,
  paragraphs: paragraphs
};

<template>
  <SimpleHeader/>

  <div class="frame1"> 
      <TypewriterTitle/>
  </div>

  <DomainCard/>

  <ProjectsCarousel/>
  
  <ContactForm/>
  
</template>

<script>
import SimpleHeader from './components/SimpleHeader.vue'
import TypewriterTitle from './components/TypewriterTitle.vue'
import ContactForm from './components/ContactForm.vue'
import ProjectsCarousel from './components/ProjectsCarousel.vue'
import DomainCard from './components/DomainCard.vue'

export default {
  components: {
    SimpleHeader,
    TypewriterTitle,
    ContactForm,
    ProjectsCarousel,
    DomainCard
}
}
</script>
